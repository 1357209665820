<template>
  <label v-bind:for="component_meta.variable" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>
  <template v-for="button in buttons" v-bind:key="button.title">
    <button type="button" @click="extend_row(button)" class="btn btn-default btn-sm bg-info">
      {{ button.title }}
    </button>
  </template>
</template>

<script>
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {

    let buttons = {};

    // 載入組件清單給下拉元件
    if (this.field.component.properties.source_json != '' && this.field.component.properties.source_json != undefined) {
      buttons = JSON.parse(this.field.component.properties.source_json);
      for (let key in buttons) {
        buttons[key] = { 'title': buttons[key] };
      }
    }
    else {

      let parameters = '';
      let params = {};

      if (this.field.component.properties.sort_rule_fields != undefined) {
        for (let i = 0; i < this.field.component.properties.sort_rule_fields.length; i++) {
          let variable = this.field.component.properties.sort_rule_fields[i].field_variable;
          let rule = this.field.component.properties.sort_rule_fields[i].rule;
          params['_s_[' + variable + ']'] = rule;
        }
      }

      if (this.field.component.properties.datazone != '' && this.field.component.properties.datazone != undefined) {
        params['dz'] = this.field.component.properties.datazone;
      }

      for (let pkey in params) {
        if (parameters != '') {
          parameters += '&';
        }
        parameters += `${pkey}=${params[pkey]}`;
      }

      if (parameters != '') {
        parameters = '?' + parameters;
      }

      let api_url = this.$microlink.base_api_url + this.field.component.properties.source_api + parameters;

      // 如果有設定快取，則從快取中取得資料
      if (this.field.component.properties.pool == 'Y') {

        let pool_key = this.$microlink.base64_encode(api_url);
        if (this.$microlink.get_data_pool(pool_key) == undefined) {
          buttons = this.$microlink.api(api_url);
          buttons = buttons.data;
          this.$microlink.set_data_pool(pool_key, buttons);
        }
        else {
          buttons = this.$microlink.get_data_pool(pool_key);
        }
      }
      else {
        buttons = this.$microlink.api(api_url);
        buttons = buttons.data;
      }
    }

    let localValue = (this.value == undefined) ? '' : this.value;

    return {
      // 元件識別碼
      component_id: 'button-list-' + Math.random().toString().substring(2),

      // 欄位資訊
      component_meta: this.field,

      // 元件值
      component_value: localValue,

      // 元件選項
      buttons: buttons,

      // 元件訊息
      component_message: this.message,

      // 元件是否被標籤化
      component_to_label: (this.field.component.properties.to_label == 'Y') ? true : false
    }
  },
  watch: {
    message: function (new_value, _) {
      _;
      this.component_message = new_value;

    }
  },
  methods: {
    extend_row: function (button) {

      let widget = (button.widget != null && button.widget != undefined && button.widget != '') ? button.widget : this.component_meta.component.properties.widget;
      let param = this.component_meta.component.properties.param;
      let extend_param = this.component_meta.component.properties.extend_param;

      let meta = {
        'type': 'button_event',
        'widget': widget,
        'param': param,
        'extend_param': {},
        'extend_title': button.title
      };
      meta['extend_param'][extend_param] = button.id;

      this.$emit('input', meta);
    }
  }
}
</script>

<style scoped>
span.tip {
  color: green;
}

button {
  margin-left: 5px;
}
</style>